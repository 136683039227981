body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 4rem;
}
div.p {
    margin: 10px 0 30px 0;
    font-size: 1.2rem;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 767px) {
    h1 {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 2rem;
    }
}

// Large devices (desktops, 992px and up)
@media (max-width: 991px) {
    h1 {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 2rem;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
