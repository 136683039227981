.p-home {
    padding-top: 10px;

    .row {
        padding-bottom: 20px;
        margin-bottom: 50px;
        align-items: center;
        //background-color: rgb(10, 93, 161);

        div.logo {

            text-align: right;
            padding-right: 20px;

            img {
                max-height: 300px;
                border-radius: 20px;
            }
        }

        div.title {
            text-align: left;
            //align-self: flex-start;

            h2 {
                //color: #fff;
                font-size: 3rem;
                color: rgb(10, 93, 161);
            }
        }

        div.mobile-stores {
            margin: 20px 0 0 20px;

            a {
                display: inline-block;
                margin: 0 15px 15px 0;
                img {
                    height: 50px;
                }
            }
        }
    }
}


// Small devices (landscape phones, 576px and up)
@media (max-width: 650px) {

    .p-home {
        .row {
            div.title {

                h2 {
                    font-size: 2rem;
                }
            }

            div.logo {

                text-align: center;

                img {
                    width: 100%;
                }
            }

            div.mobile-stores {
                margin: 10px 0;

                img {}
            }
        }
    }
}


@media (max-width: 450px) {

    .view-home {
        .row {
            div.title {
                h2 {
                    font-size: 1.3rem;
                }
            }

            div.mobile-stores {

                text-align: center;
                margin-top: 25px;

                a {                    
                }

                img {
                    height: auto;
                    width: 75%;
                }
            }
        }
    }
}