div.header {
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
}

div.header-01 {
    height: 5px;
    background-color: rgb(20, 29, 44);
    margin-bottom: 2px;
}

div.header-02 {
    height: 5px;
    background-color: rgb(24, 67, 148);
    margin-bottom: 2px;
}

div.header-03 {
    height: 5px;
    background-color: rgb(7, 152, 172);
}

div.header-spacer {
    margin-bottom: 20px;
}
