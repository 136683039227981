

footer
{
    text-align: center;
    a
    {
        text-decoration: none;
        padding-bottom: 20px;
    }
}